













































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import { Utils } from '@/app/infrastructures/misc/Utils'
import controller from '@/app/ui/controllers/CODController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { CODPaymentSetting } from '@/domain/entities/COD'
import { EnumCODPaymentSettingChargedBy } from '@/app/infrastructures/misc/Constants/cod'

export interface IForm {
  periodeStart: string
  periodeEnd: string
  feeChargedBy: string
  feeCOD: number | null
}

@Component({
  components: {
    Button,
    LoadingOverlay,
  },
})
export default class CODFeeDetail extends Vue {
  controller = controller
  form: IForm = {
    periodeStart: '',
    periodeEnd: '',
    feeChargedBy: '',
    feeCOD: null,
  }

  detailData = {
    createdAt: '-',
    createdBy: '-',
    updatedAt: '-',
    updatedBy: '-',
  }

  enumCODPaymentSettingChargedBy = EnumCODPaymentSettingChargedBy

  isDefaultCODFee = false
  isCODSettingExpired = false

  created(): void {
    this.getCODFeeDetail()
  }

  private getCODFeeDetail(): void {
    const codFeeId: number = parseInt(this.$route.params.codFeeId)

    this.controller.getCODPaymentSetting(codFeeId)
  }

  @Watch('controller.codPaymentSettingDetailData')
  onCODPaymentSettingDetailDataChanged(data: CODPaymentSetting): void {
    if (data.id) {
      this.isCODSettingExpired = data.status === 'EXPIRED'
      this.form.periodeStart = Utils.formatDateWithIDLocale(
        <string>data.periodeStart,
        'DD/MM/YYYY'
      )
      this.form.periodeEnd = Utils.formatDateWithIDLocale(
        <string>data.periodeEnd,
        'DD/MM/YYYY'
      )
      this.form.feeCOD = data.feeCod || null
      this.form.feeChargedBy = <string>data.feeChargedBy

      this.detailData.createdAt = Utils.formatDateWithIDLocale(
        <string>data.createdAt,
        'dddd, DD MMMM YYYY, HH:mm'
      )
      this.detailData.updatedAt = Utils.formatDateWithIDLocale(
        <string>data.updatedAt,
        'dddd, DD MMMM YYYY, HH:mm'
      )
      this.detailData.createdBy = data.createdBy || '-'
      this.detailData.updatedBy = data.updatedBy || '-'
      this.isDefaultCODFee = data.isDefault || false
    }
  }
}
